import * as React from "react"
import Layout from "../components/layout";
import styled from "styled-components";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby"
import Obfuscate from 'react-obfuscate';
import { SeoComponent } from "../components/seo";
import breakpoints from "../components/breakpoints";

const PrivacyPolicy = styled.section`
position: relative;
display: block;
color: #333;
overflow: hidden;
box-sizing: border-box;
padding: 80px 25% 20px 25%;
@media (max-width: ${breakpoints.tablet}) {
  padding: 80px 10% 20px 10%;
}
ul {
    li {
        font-size: 10pt;
        padding: 4px 0px;
        color: #ccc;
    }
}
`
const Paragraph = styled.p`
color: #ccc;
font-size: 10pt;
`

const Source = styled.p`
position: relative;
display: flex;
justify-content: end;
font-size: 8pt;
opacity: 0.2;
color: #999;
a {
    color: #999;
    text-decoration: none;
    font-size: 8pt;
}`

const DatenschutzerklaerungPage = () => {

    const { t } = useTranslation();

    return (
        <Layout pageTitle="Datenschutzerklärung" pageLang="de">
            <SeoComponent title={t('Datenschutz-Page.Title')} description={t('Datenschutz-Page.Description')} />

            <PrivacyPolicy>
                <h1><Trans>Datenschutz-Page.Datenschutzerklaerung</Trans></h1>
                <h2>1. <Trans>Datenschutz-Page.Section1.Datenschutz auf einen Blick</Trans></h2>
                <h3><Trans>Datenschutz-Page.Section1.Allgemeine Hinweise</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text1</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section1.Datenerfassung auf dieser Website</Trans></h3>
                <h4><Trans>Datenschutz-Page.Section1.Verantwortlichkeit</Trans></h4>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text2</Trans>
                </Paragraph>
                <h4><Trans>Datenschutz-Page.Section1.Wie erfassen wir Ihre Daten?</Trans></h4>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text3_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text3_2</Trans>
                </Paragraph>
                <h4><Trans>Datenschutz-Page.Section1.Wofuer nutzen wir Ihre Daten?</Trans></h4>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text4</Trans>
                </Paragraph>
                <h4><Trans>Datenschutz-Page.Section1.Welche Rechte haben Sie bezueglich Ihrer Daten?</Trans></h4>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text5_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text5_2</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section1.Analyse-Tools und Tools von Drittanbietern</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text6_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section1.Text6_2</Trans>
                </Paragraph>
                <h2>2. <Trans>Datenschutz-Page.Section2.Hosting</Trans></h2>
                <h3><Trans>Datenschutz-Page.Section2.Externes Hosting</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section2.Text1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section2.Text2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section2.Text3</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section2.Text4</Trans>
                </Paragraph>
                <Paragraph>
                    dogado GmbH<br />
                    Antonio-Segni-Straße 11<br />
                    44263 Dortmund<br />
                </Paragraph>
                <h2>3. <Trans>Datenschutz-Page.Section3.Allgemeine Hinweise und Pflichtinformationen</Trans></h2>
                <h3><Trans>Datenschutz</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text1_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text1_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text1_3</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Hinweis zur verantwortlichen Stelle</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text2_1</Trans>
                </Paragraph>
                <Paragraph>
                    Foxel Media GmbH<br />
                    Oehrenstöcker Chaussee 21<br />
                    98693 Ilmenau
                </Paragraph>

                <Paragraph>
                    <Trans>Impressum-Page.Telefon</Trans>: <Obfuscate tel={t('Telefonnummer')} /><br />
                    E-Mail: <Obfuscate email="info@foxel.media" />
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text2_2</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Speicherdauer</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text3</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text4</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text5</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Widerruf</Trans></h3> <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text6</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Widerspruchsrecht gegen die Datenerhebung</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text7_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text7_2</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Beschwerderecht bei der zustaendigen Aufsichtsbehoerde</Trans></h3> <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text8</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Recht auf Datenuebertragbarkeit</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text9</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Auskunft, Loeschung und Berichtigung</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text10</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.Recht auf Einschraenkung der Verarbeitung</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text11_1</Trans>
                </Paragraph>
                <ul>
                    <li>
                        <Trans>Datenschutz-Page.Section3.Text11_2</Trans>
                    </li>
                    <li>
                        <Trans>Datenschutz-Page.Section3.Text11_3</Trans>
                    </li>
                    <li>
                        <Trans>Datenschutz-Page.Section3.Text11_4</Trans>
                    </li>
                    <li>
                        <Trans>Datenschutz-Page.Section3.Text11_5</Trans>
                    </li>
                </ul>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text11_6</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section3.SSL- bzw. TLS-Verschluesselung</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text12_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section3.Text12_2</Trans>
                </Paragraph>
                <h2>4.  <Trans>Datenschutz-Page.Section4.Datenerfassung auf dieser Website</Trans></h2>
                <h3><Trans>Datenschutz-Page.Section4.Cookies</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text1_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text1_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text1_3</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text1_4</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text1_5</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text1_6</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section4.Kontaktformular</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text2_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text2_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text2_3</Trans>
                </Paragraph>
                <h3><Trans>Datenschutz-Page.Section4.Anfrage per E-Mail, Telefon oder Telefax</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text3_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text3_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section4.Text3_3</Trans>
                </Paragraph>
                <h2>5. <Trans>Datenschutz-Page.Section5.Analyse-Tools und Werbung</Trans></h2>
                <h3>Matomo</h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section5.Text1_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section5.Text1_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section5.Text1_3</Trans>
                </Paragraph>
                <h4><Trans>Datenschutz-Page.Section5.IP-Anonymisierung</Trans></h4>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section5.Text2</Trans>
                </Paragraph>
                <h4><Trans>Datenschutz-Page.Section5.Cookielose Analyse</Trans></h4>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section5.Text3</Trans>
                </Paragraph>
                <h4><Trans>Datenschutz-Page.Section5.Hosting</Trans></h4>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section5.Text4</Trans>
                </Paragraph>
                <Paragraph>
                    dogado GmbH<br />
                    Antonio-Segni-Straße 11<br />
                    44263 Dortmund<br />
                </Paragraph>
                {/* <h2>6. <Trans>Datenschutz-Page.Section6.Plugins und Tools</Trans></h2> */}
                {/* <h3><Trans>Datenschutz-Page.Section6.YouTube mit erweitertem Datenschutz</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text1_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text1_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text1_3</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text1_4</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text1_5</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text1_6</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text1_7</Trans><a href={t('Datenschutz-Page.Section6.Text1_8')} target="_blank" rel="noopener noreferrer"><Trans>Datenschutz-Page.Section6.Text1_8</Trans></a>.
                </Paragraph> */}
                {/* <h3><Trans>Datenschutz-Page.Section6.Vimeo ohne Tracking (Do-Not-Track)</Trans></h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text2_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text2_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text2_3</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text2_4</Trans>
                    <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a>.
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text2_5</Trans>
                    <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a>.
                </Paragraph> */}
                {/* <h3>Google Web Fonts</h3>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text3_1</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text3_2</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text3_3</Trans>
                </Paragraph>
                <Paragraph>
                    <Trans>Datenschutz-Page.Section6.Text3_4</Trans>
                    <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">https://developers.google.com/fonts/faq</a>
                    <Trans>Datenschutz-Page.Section6.Text3_5</Trans>
                    <a href={t('Datenschutz-Page.Section6.Text3_6')} target="_blank" rel="noopener noreferrer"><Trans>Datenschutz-Page.Section6.Text3_6</Trans></a>.
                </Paragraph> */}
                <Source><Trans>Impressum-Page.Quelle</Trans>: <a href="https://www.e-recht24.de/impressum-generator.html" target="_blank" rel="noreferrer">https://www.e-recht24.de/impressum-generator.html</a></Source>
            </PrivacyPolicy>
        </Layout>
    )
}
export default DatenschutzerklaerungPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;